import { Card, Col, Row, Table } from "antd";
import React, { useEffect, useState } from "react";

import { getDealTaskInfo, getRecHistory } from "../../api";
import * as Icon from '@ant-design/icons';
import MyEcharts from "../../components/Echarts";



const RecHistory = () => { 
    const [tableData, setTableData] = useState([])  
    const [headerData, setHeaderData] = useState([])  
    useEffect(() => {
        getRecHistory().then((data) => {
            console.log(data)
            const { headerData, tableData } = data.data
            setTableData(tableData)
            const columns = [
                ...headerData,
                    {
                        title:'link',
                        render: (text) => <a href={text['link']} target="_blank" rel="noopener noreferrer">查看记录</a>
                    }
            ]

            setHeaderData(
                columns
            )
        })
    }, [])
 
    const [sortedInfo, setSortedInfo] = useState({});

    const handleTableChange = (pagination, filters, sorter) => {
        setSortedInfo({
        columnKey: sorter.columnKey,
        order: sorter.order,
        });
        const { order, columnKey } = sorter;
        if (order && columnKey) {
            const sortedData = [...tableData].sort((a, b) => {
              if (order === 'ascend') {
                return a[columnKey] < b[columnKey] ? -1 : 1;
              } else {
                return a[columnKey] > b[columnKey] ? -1 : 1;
              }
            });
            setTableData(sortedData);
          } else {
            setTableData(tableData);
          }
    };

    const updatedColumns = headerData.map((col) => ({
        ...col,
        sortOrder: sortedInfo.columnKey === col.dataIndex ? sortedInfo.order : null,
    }));

    return (
        <div>
           <Table
                bordered
                rowKey={"userId"} 
                dataSource={tableData} 
                columns={updatedColumns} 
                pagination={{
                    defaultCurrent: 1, // 设置默认页数为第3页
                    defaultPageSize: 50, // 每页显示10条数据
                  }}  
                className="custom-table"
                onChange={handleTableChange}
           />
           </div>
    );
};

export default RecHistory