import { Card, Col, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { getHomeInfo } from "../../api";




const HomeInfo = () => {
    const [tableData, setTableData] = useState([])
    useEffect(() => {
        getHomeInfo().then((data) => {
            console.log(data.data)
            // const { headerData, tableData } = data.data
            setTableData(data.data)
            // setHeaderData(headerData)
        })
    }, [])


    return (
        <Row className="home">
            <Col span={24}>
            <h1 style={{ margin: "10px" }}>Deal统计信息</h1>
            <div>
                <Table
                    bordered
                    rowKey={"name"}
                    dataSource={tableData.dealStatsData}
                    columns={tableData.dealStatsColumns}
                    pagination={{
                        defaultCurrent: 1, // 设置默认页数为第3页
                        defaultPageSize: 20, // 每页显示10条数据
                    }}
                    className="custom-table"
                />
            </div> 
            <div>
                <h1 style={{ margin: "10px" }}>Story统计信息</h1>
                <Table
                    bordered
                    rowKey={"name"}
                    dataSource={tableData.storyStatsData}
                    columns={tableData.storyStatsColumns}
                    pagination={{
                        defaultCurrent: 1, // 设置默认页数为第3页
                        defaultPageSize: 20, // 每页显示10条数据
                    }}
                    className="custom-table"
                />
            </div>
            </Col>
        </Row>
    );
};

export default HomeInfo