import axios from "axios";
import { message, Spin } from "antd";
import React from "react";
import ReactDom from 'react-dom'
const baseUrl = '/'
// const baseUrl = 'http://localhost:8080'

axios.defaults.baseURL = baseUrl;

let requestCount = 0
function showLoadding() {
    console.log("asdf");
    if (requestCount == 0) {
        const parentDom = document.getElementById('main-content')       
        var dom = document.createElement('div')
        dom.setAttribute('id', 'loading')
        parentDom.appendChild(dom)
        ReactDom.render(<Spin tip='加载中...' size='large'/>, dom)
    }
    requestCount++
}

function hideLoading() {
    requestCount--
    if (requestCount ==0){
        const parentDom = document.getElementById('main-content')       
        parentDom.removeChild(document.getElementById('loading'))
    }
}

class HttpRequest {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    getInsideConfig() {
        const config = {
            baseUrl: this.baseUrl,
            header: {}
        }

        return config
    }

    request(options) {
        options = { ...this.getInsideConfig(), ...options }

        const instance = axios.create(options);
        this.interceptor(instance)
        return instance(options);
    }

    interceptor(instance) {

        // 添加请求拦截器
        instance.interceptors.request.use(function (config) {
             
            // 在发送请求之前做些什么
            if (config.headers.isLoading !== false) {
               
                showLoadding()
            }
            return config;
        }, function (error) {
            // 对请求错误做些什么
            if (error.config.headers.isLoading !==false) {
                hideLoading();
            }
            return Promise.reject(error);
        });

        // 添加响应拦截器
        instance.interceptors.response.use(function (response) {
           if (response.headers.isLoading !==false) {
            hideLoading();
           }

            // 2xx 范围内的状态码都会触发该函数。
            // 对响应数据做点什么
            return response;
        }, function (error) {
            // 超出 2xx 范围的状态码都会触发该函数。
            // 对响应错误做点什么
            if (error.message === 'Network Error') {
                message.warning('网络连接异常')
            }
            if (error.config === 'ECONNABORTED') {
                message.warning('请求超时，请重试!')
            }
            return Promise.reject(error);
        });
    }
}

export default new HttpRequest(baseUrl);