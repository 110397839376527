import React from "react";
import './index.css' 

import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
    DownOutlined, SmileOutlined
} from '@ant-design/icons';
import { Button, Layout, Menu, theme, Avatar, Dropdown, Space } from 'antd';

 import { useDispatch } from "react-redux";
import { collapseMenu } from "../../store/reduces/tab";
import { useNavigate } from "react-router-dom"; 




const { Header, Sider, Content } = Layout;



const CommonHeader = ({collapsed}) => {
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('token')
        navigate('/login')
    }


    const items = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer">
                    个人中心
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a onClick={() => logout()} target="_blank" rel="noopener noreferrer"  >
                    退出
                </a>
            ),
            icon: <SmileOutlined />,
            disabled: false,
        },
    ];

    const dispatch = useDispatch()

    const setCollapsed = () => {
        console.log(collapsed)
        dispatch(collapseMenu())
        console.log(collapsed)
    }
 
    return (
        <Header className="header-container">
            <Button
                type="text"
                //icon={<MenuUnfoldOutlined />}
                 icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                // onClick={() => setCollapsed(!collapsed)}
                style={{
                    fontSize: '16px',
                    width: 40,
                    height: 32,
                    backgroundColor: '#fff'
                }}
                onClick={() => setCollapsed()}
            />

            {/* <Dropdown menu={{ items}} >
                <Avatar src={require('../../assets/images/user.png')} />
            </Dropdown> */}
        </Header>


    )
}

export default CommonHeader;