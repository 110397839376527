
import { Card, Col, Row, Table,Radio, Tabs } from "antd";
import React, { useEffect, useState } from "react";


import { getCatUserStats, getData, getDealCatStats } from "../../api";
import * as Icon from '@ant-design/icons';
import MyEcharts from "../../components/Echarts";

 

const CatUserStatsInfo = () => { 
    const [echartData, setEchartData] = useState([])
    const [mode, setMode] = useState('left');
    const handleModeChange = (e) => {
        setMode(e.target.value);
    };

    useEffect(() => {
        getCatUserStats().then((data) => {
            console.log(data) 
            const items = data.data.catStats.map(function(item) {
                const firstCat = {
                    xData:item.catNames,
                    series:{
                        name: item.catName,
                        data: item.catData,
                        type: 'bar',
                        itemStyle: {        //上方显示数值
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'right', //在上方显示
                                    textStyle: { //数值样式
                                        color: 'black',
                                        fontSize: 16
                                    }
                                }
                            }
                        }
                    },
                }
                return {
                    label: item.catName,
                    key: item.catName,
                    // disabled: i === 28,
                    children: (
                        <div className="graph">
                        { firstCat && <MyEcharts chartData={firstCat} style={{ height: '1200px'}} /> }
                        </div>
                    ),
                }
            });

            console.log(items)
            setEchartData(items)
        })

    }, []) 

    return (
        // <Row  >
        //     <Col span={12}>
        //         <div className="graph">
        //         { echartData.firstCat && <MyEcharts chartData={echartData.firstCat} style={{ height: '600px' }} /> }
        //         </div>
        //     </Col>
        //     <Col span={12}>
                // <div className="graph">
                // { echartData.secondCat && <MyEcharts chartData={echartData.secondCat} style={{ height: '1800px' }} /> }
                // </div>
        //     </Col> 
        // </Row>
        <div>
            {/* <Radio.Group
                onChange={handleModeChange}
                value={mode}
                style={{
                marginBottom: 8,
                }}
            >
                <Radio.Button value="top">Horizontal</Radio.Button>
                <Radio.Button value="left">Vertical</Radio.Button>
            </Radio.Group> */}
            <Tabs
                defaultActiveKey="cat1"
                tabPosition={mode}
                // style={{
                // height: 220,
                // }}
                items={echartData}
            />
        </div>
        
    );
};
export default CatUserStatsInfo