import React, { useState } from 'react';

import * as Icon from '@ant-design/icons';

import { Button, Layout, Menu, theme } from 'antd';
import MenuConfig from '../../config';
import { useNavigate } from 'react-router-dom';



const { Header, Sider, Content } = Layout;

const iconToElement = (name) => React.createElement(Icon[name]);

const items = MenuConfig.map(item => {
    const child = {
        key: item.path,
        icon: iconToElement(item.icon),
        label: item.label
    }

    if (item.children) {
        child.children = item.children.map(subItem => {
            return {key: subItem.path, label: subItem.label}
        })
    }
    return child;
});


console.log(items)
const CommonAside = ({collapsed}) => {
    console.log(collapsed)
    const navigate = useNavigate();
    const selectMenu = (e) => {
        console.log(e)
        navigate(e.key)
    }
 
    return (
        <Sider trigger={null} collapsible collapsed = {collapsed}>
           <h3 className='app-name'>{collapsed ? 'REC':'Rec-Console'}</h3>
            <Menu
                theme="light"
                mode="inline"
                defaultSelectedKeys={['home']}
                items={items}
                style={{ height: '100%' }}
                onClick={selectMenu}
            />
        </Sider>
    )
}

export default CommonAside