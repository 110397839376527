import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

import Main from "../pages/main"; 
import Mall from "../pages/octopus"; 
import DealTaskInfo from "../pages/octopus";
import DealSourceInfo from "../pages/dealsource";
import DealCatInfo from "../pages/category";
import StoryTaskInfo from "../pages/story";
import HomeInfo from "../pages/home";
import CatUserStatsInfo from "../pages/catUser";
import Login from "../pages/login";
import RecHistory from "../pages/rec";
import SearchHistory from "../pages/search";

const routers = [
    {
        path: "/",
        Component: Main,
        children: [
          {
                path: "/",
                element: <Navigate to="home" replace/>,
          }, {
            path: "home",
            Component: HomeInfo,
          } , 
          {
            path: "octopus",
            Component: DealTaskInfo,
          } , 
          {
            path: "dealsource",
            Component: DealSourceInfo,
          }, 
          {
            path: "dealCatInfo",
            Component: DealCatInfo,
          }, 
          {
            path: "storyTasknfo",
            Component: StoryTaskInfo,
          } , 
          {
            path: "catUsernfo",
            Component: CatUserStatsInfo,
          } ,
          {
            path: "recHistory",
            Component: RecHistory,
          },
          {
            path: "searchHistory",
            Component: SearchHistory,
          }
        ]
      },
      {
        path:'/login',
        Component:Login
      }
      
];


// Or use plain objects
export default createBrowserRouter(routers);