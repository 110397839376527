import { Card, Col, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
 

import { getDealSourceInfo, getDealTaskInfo } from "../../api";
import * as Icon from '@ant-design/icons';
import MyEcharts from "../../components/Echarts";

 

const DealSourceInfo = () => { 
    const [chartData, setChartData] = useState([])   
    useEffect(() => {
        getDealSourceInfo().then((data) => {
            console.log(data) 
            setChartData({ 
                ...chartData,
                source:{
                    xData:data.data.sourceNames,
                    series:
                    {
                        name: '新增用户',
                        data: data.data.tableData,
                        type: 'bar',
                        itemStyle: {        //上方显示数值
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'right', //在上方显示
                                    textStyle: { //数值样式
                                        color: 'black',
                                        fontSize: 16
                                    }
                                }
                            }
                        }
                    },
                },
              }) 
        })
    }, [])
 

    return (
        <div>
        { chartData.source && <MyEcharts chartData={chartData.source} style={{ height: '1800px' }} /> }
        </div>
    );
};

export default DealSourceInfo