import { Card, Col, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { getStoryTaskInfo } from "../../api";
 



const StoryTaskInfo = () => { 
    const [tableData, setTableData] = useState([])   
    useEffect(() => {
        getStoryTaskInfo().then((data) => {
            console.log(data.data)
            // const { headerData, tableData } = data.data
            setTableData(data.data)
            // setHeaderData(headerData)
        })
    }, [])
 

    return (
        <div>
            <h1 style={{margin:"10px"}}>每日ProductStory Deal数据量</h1>
        <div>
           <Table
                bordered
                rowKey={"name"} 
                dataSource={tableData.productStoryData} 
                columns={tableData.productStoryColumns} 
                pagination={{
                    defaultCurrent: 1, // 设置默认页数为第3页
                    defaultPageSize: 20, // 每页显示10条数据
                  }}  
                className="custom-table"
           />
           </div>
           <div>
           <h1 style={{margin:"10px"}}>每日QueryStory Video数据量</h1>
           <Table
                bordered
                rowKey={"name"} 
                dataSource={tableData.queryStoryDayData} 
                columns={tableData.queryStoryDayColumns} 
                pagination={{
                    defaultCurrent: 1, // 设置默认页数为第3页
                    defaultPageSize: 20, // 每页显示10条数据
                  }}  
                className="custom-table"
           />
           </div>
           
           <div>
           <h1 style={{margin:"10px"}}>最近七日QueryStory Video数据量</h1>
           <Table
                bordered
                rowKey={"name"} 
                dataSource={tableData.queryStoryData
                } 
                columns={tableData.queryStoryColumns} 
                pagination={{
                    defaultCurrent: 1, // 设置默认页数为第3页
                    defaultPageSize: 20, // 每页显示10条数据
                  }}  
                className="custom-table"
           />
           </div>
           </div>
    );
};

export default StoryTaskInfo