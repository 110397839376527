export default  [
    {
        path: '/home',
        name: 'home',
        label: 'Overview',
        icon: 'HomeOutlined',
        url: '/home/index'
    },
    {
        path: '/octopus',
        name: 'octopus',
        label: '爬虫任务监控',
        icon: 'HomeOutlined',
        url: '/octopus/index'
    },
    {
        path: '/dealsource',
        name: 'dealsource',
        label: 'Deal Souce',
        icon: 'HomeOutlined',
        url: '/dealsource/index'
    },
    {
        path: '/dealCatInfo',
        name: 'dealCatInfo',
        label: 'Deal Cat Stats',
        icon: 'HomeOutlined',
        url: '/dealcat/index'
    },
    {
        path: '/storyTasknfo',
        name: 'storyTaskInfo',
        label: 'Story生成任务监控',
        icon: 'HomeOutlined',
        url: '/story/index'
    },
    {
        path: '/catUsernfo',
        name: 'catUsernfo',
        label: '分类用户信息',
        icon: 'HomeOutlined',
        url: '/catUser/index'
    },
     {
         path: '/recHistory',
         name: 'recHistory',
         label: '推荐历史',
         icon: 'HomeOutlined',
         url: '/rec/index'
     },
     {
         path: '/searchHistory',
         name: 'searchHistory',
         label: '搜索历史',
         icon: 'HomeOutlined',
         url: '/search/index'
     }
]