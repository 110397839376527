import http from './axios'


export const getDealTaskInfo = () => {
    return http.request({
        url:'/console/getDealTaskInfo',
        method: 'get',
        Headers:{
            'isLoading':true
        }
    })
}  


export const getDealSourceInfo = () => {
    return http.request({
        url:'/console/getDealSourceInfo',
        method: 'get',
        Headers:{
            'isLoading':true
        }
    })
}  

export const getDealCatStats = () => {
    return http.request({
        url:'/console/getDealCatStats',
        method: 'get',
        Headers:{
            'isLoading':true
        }
    })
}  

export const getStoryTaskInfo = () => {
    return http.request({
        url:'/console/getStoryTaskInfo',
        method: 'get',
        Headers:{
            'isLoading':true
        }
    })
}  


export const getHomeInfo = () => {
    return http.request({
        url:'/console/getHomeStats',
        method: 'get',
        Headers:{
            'isLoading':true
        }
    })
}  

export const getCatUserStats = () => {
    return http.request({
        url:'/console/getCatUserStats',
        method: 'get',
        Headers:{
            'isLoading':true
        }
    })
}

export const getRecHistory = () => {
    return http.request({
        url:'/console/getRecHistory',
        method: 'get',
        Headers:{
            'isLoading':true
        }
    })
}

export const getSearchHistory = () => {
    return http.request({
        url:'/console/getSearchHistory',
        method: 'get',
        Headers:{
            'isLoading':true
        }
    })
}

