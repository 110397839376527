
import { Card, Col, Row, Table,Radio, Tabs } from "antd";
import React, { useEffect, useState } from "react";


import { getData, getDealCatStats } from "../../api";
import * as Icon from '@ant-design/icons';
import MyEcharts from "../../components/Echarts";

 

const DealCatInfo = () => { 
    const [echartData, setEchartData] = useState([])
    const [mode, setMode] = useState('left');
    const handleModeChange = (e) => {
        setMode(e.target.value);
    };
    useEffect(() => {
        getDealCatStats().then((data) => {
            console.log(data) 
            setEchartData({
                ...echartData,
                firstCat: {
                    xData:data.data.firstCatNames,
                    series:{
                        name: '一级类目统计',
                        data: data.data.firstCatData,
                        type: 'bar',
                        itemStyle: {        //上方显示数值
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'right', //在上方显示
                                    textStyle: { //数值样式
                                        color: 'black',
                                        fontSize: 16
                                    }
                                }
                            }
                        }
                    },
                },
                secondCat: {
                    xData:data.data.secondCatNames,
                    series:{
                        name: '二级级类目统计',
                        data: data.data.secondCatData,
                        type: 'bar',
                        itemStyle: {        //上方显示数值
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'right', //在上方显示
                                    textStyle: { //数值样式
                                        color: 'black',
                                        fontSize: 16
                                    }
                                }
                            }
                        }
                    },
                },
            })
        })

    }, [])
    const iconToElement = (name) => React.createElement(Icon[name]);


   

    return (
        // <Row  >
        //     <Col span={12}>
        //         <div className="graph">
        //         { echartData.firstCat && <MyEcharts chartData={echartData.firstCat} style={{ height: '600px' }} /> }
        //         </div>
        //     </Col>
        //     <Col span={12}>
                // <div className="graph">
                // { echartData.secondCat && <MyEcharts chartData={echartData.secondCat} style={{ height: '1800px' }} /> }
                // </div>
        //     </Col> 
        // </Row>
        <div>
            {/* <Radio.Group
                onChange={handleModeChange}
                value={mode}
                style={{
                marginBottom: 8,
                }}
            >
                <Radio.Button value="top">Horizontal</Radio.Button>
                <Radio.Button value="left">Vertical</Radio.Button>
            </Radio.Group> */}
            <Tabs
                defaultActiveKey="cat1"
                tabPosition={mode}
                // style={{
                // height: 220,
                // }}
                items={[
                    {
                        label: '一级类目',
                        key: "cat1",
                        // disabled: i === 28,
                        children: (
                            <div >
                            { echartData.firstCat && <MyEcharts chartData={echartData.firstCat} style={{ height: '360px'}} /> }
                            </div>
                        ),
                    },
                    {
                        label: '二级级类目',
                        key: "cat2",
                        // disabled: i === 28,
                        children: (
                            <div className="graph">
                            { echartData.secondCat && <MyEcharts chartData={echartData.secondCat} style={{ height: '1800px' }} /> }
                            </div>
                        ),
                    }

                ]}
            />
        </div>
        
    );
};
export default DealCatInfo