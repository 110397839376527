import { Card, Col, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import './index.css' 

import { getDealTaskInfo } from "../../api";
import * as Icon from '@ant-design/icons';
import MyEcharts from "../../components/Echarts";



const DealTaskInfo = () => { 
    const [tableData, setTableData] = useState([])  
    const [headerData, setHeaderData] = useState([])  
    useEffect(() => {
        getDealTaskInfo().then((data) => {
            console.log(data)
            const { headerData, tableData } = data.data
            setTableData(tableData)
            setHeaderData(headerData)
        })
    }, [])
 

    return (
        <div>
           <Table
                bordered
                rowKey={"name"} 
                dataSource={tableData} 
                columns={headerData} 
                pagination={{
                    defaultCurrent: 1, // 设置默认页数为第3页
                    defaultPageSize: 20, // 每页显示10条数据
                  }}  
                className="custom-table"
           />
           </div>
    );
};

export default DealTaskInfo